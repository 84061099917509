import * as React from 'react';
import classnames from 'classnames';

import './styles.scss';
import LINKS from 'constans/links';

const NotFoundPage = () => {
  return (
    <main className={classnames('overflow-hidden')}>
      <h1>404</h1>

      <p className="oc-h3 mb-16">Sorry, page not found.</p>

      <a href={LINKS.internal.home} className="btn btn--fill btn--pomegranate">
        go back to home
      </a>
    </main>
  );
};

export default NotFoundPage;
